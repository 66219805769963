import React from 'react'; 
import ReactDOM from 'react-dom/client'; 
import { Footer } from './components';

import App from './App'; 

const root = ReactDOM.createRoot(document.getElementById('root')); 
const footer = ReactDOM.createRoot(document.getElementById('footer')); 
root.render( 
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
)

