import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Home, Gallery } from './pages/index';
import { Navbar } from './components';
import NotFound from './components/handlers/404';
import './styles/index';

function App() {
  const [effectsEnabled, setEffectsEnabled] = useState(
    () => JSON.parse(localStorage.getItem('effectsEnabled')) ?? true
  );

  useEffect(() => {
    if (effectsEnabled) {
      enableEffects();
    } else {
      disableEffects();
    }

    localStorage.setItem('effectsEnabled', JSON.stringify(effectsEnabled));
    return () => {
      disableEffects();
    };
  }, [effectsEnabled]);

  const enableEffects = () => {
    const numStars = 100;

    const starsContainer = document.createElement('div');
    starsContainer.className = 'stars-container';
    document.body.appendChild(starsContainer);

    for (let i = 0; i < numStars; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        star.style.top = `${Math.random() * 100}vh`;
        star.style.left = `${Math.random() * 100}vw`;
        star.style.animationDuration = `${5 + Math.random() * 15}s`;
        star.style.animationDelay = `${Math.random() * 10}s`;

        // Remove the star when its animation ends
        star.addEventListener('animationend', () => {
            star.remove();
        });
        starsContainer.appendChild(star);
    }

    const cursor = document.createElement('div');
    cursor.className = 'custom-cursor';
    document.body.appendChild(cursor);
    
    let lastX = 0;
    let lastY = 0;
    let currentX = 0;
    let currentY = 0;
    let currentAngle = 0;
    let targetAngle = 0;

    let isThrottled = false;

    const handleMouseMove = (e) => {
        if (!isThrottled) {
            lastX = e.clientX;
            lastY = e.clientY;
            isThrottled = true;

            setTimeout(() => {
                isThrottled = false;
            }, 8); // ~60 FPS
        }
    };

    document.addEventListener('mousemove', handleMouseMove);

    const smoothUpdate = () => {
      currentX += (lastX - currentX) * 0.2;
      currentY += (lastY - currentY) * 0.2;
  
      const dx = currentX - lastX;
      const dy = currentY - lastY;
  
      if (Math.abs(dx) > 0.1 || Math.abs(dy) > 0.1) {
          targetAngle = Math.atan2(dy, dx) * (180 / Math.PI) - 90;
      }
  
      // Normalize the angle difference to ensure smooth rotation
      let angleDifference = targetAngle - currentAngle;
      angleDifference = ((angleDifference + 180) % 360) - 180; // Normalize to range [-180, 180]
  
      currentAngle += angleDifference * 0.1; // Adjust 0.2 for smoother or faster rotation
  
      cursor.style.left = `${currentX}px`;
      cursor.style.top = `${currentY}px`;
      cursor.style.transform = `translate(-50%, -50%) rotate(${currentAngle}deg)`;
  
      requestAnimationFrame(smoothUpdate);
  };

    smoothUpdate();
    document.documentElement.style.setProperty('cursor', 'none');

};


  const disableEffects = () => {
    document.querySelectorAll('.stars-container, .custom-cursor').forEach((el) => el.remove());
    document.removeEventListener('mousemove', () => {});
    document.documentElement.style.setProperty('cursor', 'default');

  };

  return (
    <BrowserRouter>
      <Navbar onToggleEffects={setEffectsEnabled} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
