import React from 'react';
import Slider from './slider';
//import { images } from '../../images/index';
import './home.css';

const toggleProjects = (id) => {
    const content = document.getElementById(id);
    if (content.style.maxHeight) {
        // Collapse the content
        content.style.maxHeight = null;
        content.style.opacity = 0;
        content.style.padding = "0px 5px";
        content.style.margin = "0px 0px";
    } else {
        // Expand the content
        content.style.maxHeight = content.scrollHeight + "px";
        content.style.opacity = 1;
        content.style.padding = "0px 5px";
        content.style.margin = "10px 0px 5px 0px";
    }
};


const projectsData = {
    other: {
        label: "PHP",
        width: "90%",
        time: "500+ hours",
        projects: [
            "Designing and developing a dashboard for a company",
            "Wordpress configuration and customization",
            "Maintenance of Apache PHP Focused Servers and Services",
            "API development",
            "Private projects for fun"
        ],

    },
    typescript: {
        label: "React",
        width: "75%",
        time: "100+ hours",
        projects: [
            "Portfolio website"
        ]
    }
};



const welcome = () => (

    <main>

        <section id="intro" className="section sectionHeight">
            <h1>Hey, I'm Xylia ❣</h1>
            <p>My unmatched perspicacity coupled with sheer indefatigability makes me a feared opponent in any
                realm of the human endeavour!</p>
        </section>
        <section id="about-me" className="section">
            <div className="split">
                <div className="split-1">
                    <h2>About Me</h2>
                    <p>A full-stack developer who mainly finds interests in <strong>System Administration and Network Management. </strong>
                        I enjoy helping people with their projects and doing the things which people deem impossible. I
                        love breaking the limits and pushing the boundaries of what's possible.
                    </p>
                    <p>In my free time I love listening to <strong>music</strong> and reading books. Most of the time
                        I'm listening to <strong>music</strong> because that is how I cope with all the positive and all
                        the negative things.
                    </p>
                    <a href="#personality" className="more-info-link">↓ More Info</a>
                </div>
                {/*<img className='rounded profile-picture' src={images.profilePicute} alt="Profile" />*/}
                <img className='rounded pfp-dance' src='icons/XyliaHello.png' alt="Profile" />
            </div>
        </section>

        <Slider />

        {/*<section className="section">
            <div className="wrapper">
                <h1>Projects</h1>
                <h5>Coming Soon</h5>
            </div>
        </section>*/}

        <section className="section fit-content">
            <div className="wrapper">
                <h1 className="cards-title">What games do I play?</h1>
                <div className="cards">

                    {[
                        {
                            id: "minecraft",
                            title: "Minecraft",
                            description: "As a retired Minecraft veteran, I once knew every secret, mechanic, and strategy in this blocky world. From complex redstone contraptions to surviving in Hardcore mode, I was deeply immersed. Although I haven't kept up with recent updates, my love for the game remains strong, even if my knowledge has faded over time.",
                            playtime: "25,000+ hours",
                            url: "https://upload.wikimedia.org/wikinews/en/7/7a/Minecraft_game_cover.jpeg",
                            height: "500px"
                        },
                        {
                            id: "omega-strikers",
                            title: "Omega Strikers",
                            description: "Omega Strikers was once a go-to game for me, providing countless hours of fast-paced fun and unique gameplay. Sadly, with the developers having seemingly abandoned it, the game's potential feels wasted. Despite its current state, I still cherish the time I spent and the memories I made with it.",
                            playtime: "800+ hours",
                            url: "https://upload.wikimedia.org/wikipedia/en/8/89/Omega_Strikers_Game_Cover.jpg",
                            height: "500px"
                        },
                        {
                            id: "valorant",
                            title: "Valorant",
                            description: "Valorant is a game that truly tests my patience and competitive spirit. I enjoy the tactical intensity, the precise aiming, and the challenge of mastering each agent's abilities. Despite the occasional urge to throw my keyboard out the window, I love the thrill of competition. My ultimate goal is to reach the top rank, pushing myself to become a better player with each match.",
                            playtime: "900+ hours",
                            url: "https://cdn2.steamgriddb.com/grid/9edb6b9b7fc3b263b86740c635839dc4.png",
                            height: "500px"
                        },
                        {
                            id: "apex",
                            title: "Apex Legends",
                            description: "Apex Legends is a constant reminder that there's always room for improvement. I'm still working on my skills, especially when it comes to mastering the fast-paced movement, precise aiming, and efficient looting. Though my performance isn't top-tier yet, I remain determined to get better and enjoy the chaos along the way.",
                            playtime: "50+ hours",
                            url: "https://preview.redd.it/f2o8syw3nhd51.png?auto=webp&s=bfa2550586562b3964c630fab1babf3af031ac11",
                            height: "500px"
                        },

                        {
                            id: "csgo",
                            title: "Counter Strike",
                            description: "My time in CS:GO has been primarily spent practicing bunny hopping and improving my movement skills. I enjoy exploring the fluidity of the game mechanics, trying to perfect techniques that many players overlook. Although I haven't tracked my total playtime, I've enjoyed the process of pushing myself to master the subtleties of in-game movement.",
                            playtime: "Unknown",
                            url: "https://static.displate.com/857x1200/displate/2023-06-12/6e217abc7f5bb5d0dc56e68752193a11_5c51574f5f2f216f9ef25a0d08fa6400.jpg",
                            height: "500px"
                        },
                        {
                            id: "EuroTruckSimulator2",
                            title: "Euro Truck Simulator 2",
                            description: "With my G920 steering wheel and shifter, Euro Truck Simulator 2 offers a deeply immersive and relaxing experience. From driving long-haul routes across Europe to managing cargo, this game has been a great way for me to unwind. There's something incredibly satisfying about the mix of simulation and exploration.",
                            playtime: "69 hours",
                            url: "https://upload.wikimedia.org/wikipedia/en/0/0e/Euro_Truck_Simulator_2_cover.jpg",
                            height: "500px"
                        }
                    ].map(game => (
                        <div key={game.id} id={`game-${game.id}`} className="card">
                            <img src={game.url} alt={game.title} id={game.title} onError={(e) => { e.target.style.display = 'none';}} style={{ height: game.height }} />
                            <div className="placeholder" id={game.title}>No Image Available</div>
                            <h2>{game.title}</h2>
                            <p>{game.description}</p>
                            <h3 className="playtimeAll">Playtime: <span className="playtime">{game.playtime}</span></h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>

        <section id="stats" className="section">
            <div className="stats-stats">
                <h2>Programming Data:</h2>

                {Object.entries(projectsData).map(([key, data]) => (
                    <div className="stat-item" key={key}>
                        <div className="stat-info">
                            <span className="label">{data.label}:</span>
                            <div className="progress-bar-wrapper">
                                <div className="progress-bar">
                                    <div className="progress" style={{ width: data.width }}></div>
                                </div>
                            </div>
                            <span className="time">{data.time}</span>
                            <button className="projects-toggle" onClick={() => toggleProjects(`${key}-projects`)}>
                                Projects
                            </button>
                        </div>
                        <div className="projects-content" id={`${key}-projects`}>
                            <ul>
                                {data.projects.map((project, index) => (
                                    <ul key={index}>
                                        <div className="project">➢  {project}</div>
                                    </ul>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </section>



    </main>
);

export default welcome;
