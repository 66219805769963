import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Link as SmoothScroll } from "react-scroll";

import './navbar.css';
import { images } from '../../images/index';

const Navbar = ({ onToggleEffects }) => {
  const [effectsEnabled, setEffectsEnabled] = useState(
    () => JSON.parse(localStorage.getItem('effectsEnabled')) ?? true
  );

  const handleToggle = () => {
    const newState = !effectsEnabled;
    setEffectsEnabled(newState);
    onToggleEffects(newState);
  };

  return (
    <header>
      <div className="navbar_left">
        <img className="img-round" src='icons/XyliaHello.png' alt="pfp" id="pfp" />
      </div>
      <div id="border">
        <nav>
          <ul className="nav__links">
            <li><Link to="">Home</Link></li>
            <li><SmoothScroll to="about-me" smooth={true}>About</SmoothScroll></li>
            <li><Link to="gallery">Gallery</Link></li>
          </ul>
        </nav>
        <div className="effects-container">
          <p>Effects</p>
          <div className="tooltip">
            Toggle this to disable all effects. <br />
            Recommended for weaker computers.
          </div>
          <label className="switch">
            <input 
              type="checkbox" 
              checked={effectsEnabled} 
              onChange={handleToggle} 
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
